import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient) { }

  postRequest(theme: string , hostname: string, endpoint: string, jsonBody: any): Observable<any> {
    const url = environment.serverUrl + environment.decode;
    return this.http.post<any>(url, jsonBody);
  }

  getRequestData(theme, endpoint: string): Observable<any> {
    const url = environment.serverUrl + environment.legends;
    return this.http.get<any>(url);

  }
}
