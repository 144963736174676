import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from './common/model/ApiResponse';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {environment} from '../environments/environment';
import {RequestService} from './common/services/request.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Hays Bias Decoder';
  htmlContent : string;
  legends: string[];
  biasData: any;
  orientation : string = 'neutral';

  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: true,
    showToolbar: false,
    maxHeight: '400'
  };

  constructor(private requestService: RequestService) { }

  ngOnInit() {
    // this.getLegends();
  }

  getLegends() {
    this.requestService.getRequestData('', '').subscribe(resp => {
      console.log('Getting Legends ' , resp);

      if (resp.status === 'HAYS_200') {
        this.legends = resp.data;
      }
    });
  }

  checkBias() {
    console.log('Text to check ' , this.htmlContent);

    const json = {
      searchText: undefined
    };

    console.log('HTML Content = ' , this.htmlContent);

    const plainText = this.htmlContent.replace(/<[^>]*>/g, '').replace(/&#160;/g , ' ');

    console.log('Plain Text = ' , plainText);

    json.searchText = plainText;

    this.requestService.postRequest('', '', '', json).subscribe((res: ApiResponse) => {
      console.log('Bias Response = ' , res);

      if (res.status === 'HAYS_200') {
        const data = res.data;

        this.biasData = data;
        console.log('Bias Data = ', data);

        // tslint:disable-next-line:forin
        for (const key in res.data) {
          console.log('key:', key, 'value:', res.data[key]);

          const category = key;
          const catValues = res.data[key];

          console.log('Category Values = ', catValues);

          let color = '#002276';

          if (category === 'female') {
            color = '#DF4C9D';
          }

          catValues.forEach(word => {
            if (this.htmlContent.indexOf(word) > -1) {
              console.log('Word is Biased ', word, ' category = ', category);

              this.htmlContent = this.replaceContent(this.htmlContent , word , color);

            }
          });

          console.log('Updated Content = ', this.htmlContent);
        }
      }
    });
  }

  replaceContent(text , word , color) {
    const escapedFind = word.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return text.replace(new RegExp(escapedFind, 'g'), '<span style="color:' + color + '; font-weight: bold">' + word + '</span> ');
  }

  clearText(){
    this.htmlContent = '';
  }
}
